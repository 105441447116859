import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'previousMisconduct',
    'previousMisconductFields',
    'brokerTypeRadio',
    'removedFromPanelRationale',
    'warningsAndDisciplinaryActionsRationale',
    'suspendedFromActivitiesRationale',
    'removedFromPanelDiv',
    'suspendedFromActivitiesDiv',
    'warningsAndDisciplinaryActionsDiv',
    'removedFromPanelRadioButton',
    'suspendedFromActivitiesRadioButton',
    'warningsAndDisciplinaryActionsRadioButton',
  ];

  connect() {
    this.togglePreviousMisconduct();
  }

  toggleSuspendedFromActivities() {
    this.suspendedFromActivitiesDivTarget.classList.toggle('u-hidden', !this.suspendedFromActivitiesRadioButtonTarget.checked);
    this.suspendedFromActivitiesRationaleTarget.toggleAttribute('required', this.suspendedFromActivitiesRadioButtonTarget.checked);
    if (!this.suspendedFromActivitiesRadioButtonTarget.checked) {
      this.suspendedFromActivitiesRationaleTarget.value = '';
    }
  }

  toggleWarningsAndDisciplinaryActions() {
    this.warningsAndDisciplinaryActionsDivTarget.classList.toggle('u-hidden', !this.warningsAndDisciplinaryActionsRadioButtonTarget.checked);
    this.warningsAndDisciplinaryActionsRationaleTarget.toggleAttribute('required', this.warningsAndDisciplinaryActionsRadioButtonTarget.checked);
    if (!this.warningsAndDisciplinaryActionsRadioButtonTarget.checked) {
      this.warningsAndDisciplinaryActionsRationaleTarget.value = '';
    }
  }

  toggleRemovedFromPanel() {
    this.removedFromPanelDivTarget.classList.toggle('u-hidden', !this.removedFromPanelRadioButtonTarget.checked);
    this.removedFromPanelRationaleTarget.toggleAttribute('required', this.removedFromPanelRadioButtonTarget.checked);
    if (!this.removedFromPanelRadioButtonTarget.checked) {
      this.removedFromPanelRationaleTarget.value = '';
    }
  }

  togglePreviousMisconduct() {
    this.previousMisconductTarget.classList.toggle('u-hidden', !this.brokerTypeRadioTarget.checked);

    this.previousMisconductFieldsTargets.forEach((pmt) => {
      pmt.toggleAttribute('disabled', !this.brokerTypeRadioTarget.checked);
    });
  }
}
