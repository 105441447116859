import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import getCSRFToken from '../src/getCSRFToken';
import debounce from '../src/debounce';

export default class extends Controller {
  static targets = ['form', 'automationTitle', 'automationDescription'];

  connect() {
    this.debouncedSave = debounce(this.saveAutomation.bind(this), 500);
  }

  async saveAutomation() {
    if (!this.automationTitleTarget.value) { return; }

    Turbo.navigator.submitForm(this.formTarget);
  }
}
