import { Controller } from '@hotwired/stimulus';

export default class FormsController extends Controller {
  static targets = ['formInput'];

  submit() {
    this.formInputTarget.requestSubmit();
    this.formInputTarget.reset();
  }


  scrollToBottom() {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 250);
  }

  resetForm() {
    this.formInputTarget.reset();
  }
}
