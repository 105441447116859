<template>
  <!--
    TODO: this should be a button, but its only current use is nested within another button which
    isn't valid HTML
  -->
  <div
    type="button"
    :class="[
      'u-block',
      'u-border',
      'u-rounded-[4px]',
      'u-border-grey',
      'u-py-1',
      'u-px-2',
      'u-w-44',
      'u-text-left',
      'u-truncate',
      { 'u-bg-grey-30': active },
    ]"
    @click="$emit('btn-clicked')"
  >
    <slot>
      {{ text }}
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,

      required: true,
    },
    text: {
      type: String,

      default: null,
    },
  },
};
</script>
