export function checkValidity(input, errorMessage, regex) {
  if (input.classList.contains('is-invalid')) { removePreviousError(input); }

  const value = input.value.trim();
  if (!regex.test(value)) {
    addError(input, errorMessage);
  } else {
    removeErrorStyling(input);
  }
}

export function checkAccountNumberValidity(input) {
  checkValidity(input, 'Account number must be 8 digits long', /^\d{8}$/);
}

export function checkAccountNameValidity(input) {
  checkValidity(input, 'Account name is required', /.+/);
}

export function checkSortCodeValidity(input) {
  checkValidity(input, 'Sort code must be 6 digits long', /^\d{6}$/);
}

function removePreviousError(input) {
  input.nextElementSibling?.remove();
}

function addError(input, errorMessage) {
  input.classList.add('is-invalid', '!u-border-red');
  input.insertAdjacentHTML('afterend', `<div class="invalid-feedback u-text-red u-text-sm u-col-start-4 u-col-span-7">${errorMessage}</div>`);
}

function removeErrorStyling(input) {
  input.classList.remove('is-invalid', '!u-border-red');
}
