import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import getCSRFToken from '../src/getCSRFToken';
import loader from '../src/trix_loader';
import debounce from '../src/debounce';

export default class CasesNotesController extends Controller {
  static targets = [
    'editor',
    'savingIndicator',
  ];

  static values = {
    noteSectionId: String,
    noteId: String,
  };

  editorTargetConnected() {
    loader();
  }

  connect() {
    this.debouncedSave = debounce(this.saveNote.bind(this), 500);
  }

  save() {
    this.debouncedSave();
  }

  async saveNote() {
    this.savingIndicatorTarget.appendChild(document.createElement('i')).classList.add('u-ml-2', 'fa', 'fa-spinner', 'fa-spin');

    await axios.patch(
      `/note_sections/${this.noteSectionIdValue}/notes/${this.noteIdValue}`,
      { cases_note: { content: this.editorTarget.value }, authenticity_token: getCSRFToken() },
    ).then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          this.savingIndicatorTarget.innerHTML = response.data;
        }, 200);
      }
    });
  }
}
