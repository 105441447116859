<template>
<dropdown-component
  :active="active"
  :content-classes="contentClasses"
  @toggle="(newActive) => active = newActive"
>
  <template #toggle>
    <icon
      :class-list="['u-mx-2', 'u-py-1']"
      icon-style="none"
      name="ellipsis"
      :size="20"
    />
  </template>

  <template #content>
    <dropdown-item
      v-for="(action, key) in actions"
      :key="key"
      :value="key"
      @item-clicked="executeAction"
    >
      <icon
        :name="action.icon"
        :icon-style="action.style"
        :size="14"
      />

      {{ action.text }}
    </dropdown-item>
  </template>
</dropdown-component>
</template>

<script>
import * as DropdownComponents from './dropdown';
import Icon from './icon.vue';

export default {
  components: {
    ...DropdownComponents,
    Icon,
  },
  props: {
    editable: {
      type: Boolean,

      default: true,
    },
    contentClasses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    actions() {
      const actionsObject = {};

      if (this.editable) {
        actionsObject.edit = {
          icon: 'edit',
          text: 'Edit',
          style: 'ring',
        };
      }

      actionsObject.delete = {
        icon: 'bin',
        text: 'Delete',
        style: 'ring',
      };

      return actionsObject;
    },
  },
  methods: {
    executeAction(action) {
      this.$emit('action-clicked', action);
    },
  },
};
</script>
