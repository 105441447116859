import axios from 'axios';
import * as Sentry from '@sentry/vue';
import flash from './flash';

Sentry.init({
  // Vue,
  dsn: window._sentry_dsn,
  environment: 'production',
  logErrors: true,
});

const errorToastAndStopUpload = (event) => {
  event.preventDefault();
  flash('error', 'The file upload was cancelled due to an error or a suspicious file type.');
  return false;
};

const xhrUploadChecks = async (event) => {
  try {
    const formData = new FormData();
    formData.append('file', event.file);

    const response = await axios.post('/upload_checks/check.json', formData, {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    });

    const responseText = response.data;

    if (!responseText.content_type_allowed) {
      Sentry.captureException(`Cancelled file upload. Shallow content type: ${event.file.type}. Deep content type: ${responseText.deep_content_type}`);
      errorToastAndStopUpload(event);
    } else if (response.status !== 200) {
      Sentry.captureException(`Cancelled file upload due to error: ${response.statusText}`);
      errorToastAndStopUpload(event);
    }
  } catch (error) {
    Sentry.captureException(`Error during upload checks: ${error}`);
    errorToastAndStopUpload(event);
  }
};

// Prevent uploading files for fields where that is not enabled.
const preventDisallowedUploads = (event) => {
  let targetDisallowsUploads = true;
  const classes = event.target.classList;
  for (let i = 0; i < classes.length; i += 1) {
    if (classes[i] === 'show-upload') {
      targetDisallowsUploads = false;
    }
  }
  if (targetDisallowsUploads) {
    event.preventDefault();
    flash('error', 'This field does not allow file uploads.');
  }
  return targetDisallowsUploads;
};

const preventLargeUploads = (event) => {
  if (!event.file) { return true; }
  const maxFileSizeInMegabytes = 25;
  const megabyte = 1048576;
  if (event.file.size > (maxFileSizeInMegabytes * megabyte)) {
    event.preventDefault();
    Sentry.captureException(`The file is too large (${event.file.size}). You can only upload files smaller than ${maxFileSizeInMegabytes} MB (${maxFileSizeInMegabytes * megabyte}).`);
    flash('error', `The file is too large. You can only upload files smaller than ${maxFileSizeInMegabytes} MB.`);
    return true;
  }
  return false;
};

export const acceptedTypes = [
  'application/msword',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-ole-storage',
  'image/heic',
  'image/heif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
];

// Screen out files with disallowed file types.
// NB The file-type can easily be spoofed, so this is not for security; it's just a way to give the user quick visual feedback without needing to spend lots of time and memory on the check.
const preventDisallowedFileTypes = (event) => {
  if (!event.file) { return true; }

  const fileType = event.file.type;
  if (!acceptedTypes.includes(fileType)) {
    event.preventDefault();
    Sentry.captureException(`The file upload was cancelled as the file type '${fileType}' is not allowed.`);
    flash('error', 'The file upload was cancelled as the file type is not allowed.');
    return true;
  }
  return false;
};

const uploadChecks = async (event) => {
  try {
    if (preventDisallowedUploads(event)) { return; }
    if (preventDisallowedFileTypes(event)) { return; }
    if (preventLargeUploads(event)) { return; }
    await xhrUploadChecks(event);
  } catch (error) {
    Sentry.captureException(error);
    errorToastAndStopUpload(event);
  }

  // TODO: Scan for viruses.
};

const fileTypeError = (file) => {
  if (!acceptedTypes.includes(file.type)) {
    Sentry.captureException(`The file type '${file.type}' is not on the list of allowed file types for uploading.`);
    flash('error', `${file.name}: this file type is not supported`);
    return true;
  }
  return false;
};

export { fileTypeError, uploadChecks };
