import { Controller } from '@hotwired/stimulus';
import Vue from 'vue';
import ActionsDropdown from '../src/components/actionsDropdown.vue';

export default class extends Controller {
  static targets = ['modal', 'actionsDropdown'];

  showModal(event) {
    event.preventDefault();
    this.modalTarget.showModal();
  }

  actionsDropdownTargetConnected(el) {
    const { fundsReleaseId } = el.dataset;

    const app = new Vue({
      components: {
        ActionsDropdown,
      },
      data() {
        return { fundsReleaseId };
      },
      methods: {
        handleAction(action) {
          if (action === 'edit') {
            document.getElementById(`editFundsReleaseModal-${this.fundsReleaseId}`).showModal();
          } else if (action === 'delete') {
            document.getElementById(`deleteFundsReleaseModal-${this.fundsReleaseId}`).showModal();
          }
        },
      },
    });

    app.$mount(el);
  }
}
