// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery';

import 'bootstrap';
import 'bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import '@hotwired/turbo-rails';

import ahoy from 'ahoy.js';
import autosize from 'autosize';

import '../controllers';

import scrollFor from '../src/scroll_for_design_2023';
import loadTrix from '../src/trix_loader';
import applyTurboLoading from '../src/turbo_loading';

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('@rails/actiontext');

require('vue2-dropzone/dist/vue2Dropzone.min.css');

const NAV_BAR_SELECTOR = '#js-nav-bar';
const NAV_BAR_MENU_BUTTON_SELECTOR = '#js-nav-bar-btn';
const NAV_BAR_MENU_SELECTOR = '#js-nav-bar-menu';
const NOTIFICATIONS_CLOSE_BUTTON_SELECTOR = '.js-close-notifications-btn';
const NAV_BAR_NOTIFICATIONS_SELECTOR = '#js-nav-bar-notifications-menu';

const TOGGLE_CASE_FILES_SELECTED_BUTTON_SELECTOR = '.js-toggle-case-files-selected';
const CASE_FILES_FORM_SELECTOR = '.js-case-files-form';

function toggleAllCaseFilesSelected() {
  const toggleButtonEl = document.querySelector(TOGGLE_CASE_FILES_SELECTED_BUTTON_SELECTOR);

  if (!toggleButtonEl) { return; }

  const caseFilesForm = document.querySelector(CASE_FILES_FORM_SELECTOR);

  toggleButtonEl.addEventListener('click', () => {
    const checkboxes = $(caseFilesForm).find(':checkbox');
    checkboxes.prop('checked', !checkboxes.prop('checked'));
  });
}

function handleOutsideClick(buttonEl, dropdownEl, buttonHandler) {
  return function onOutsideClick(event) {
    if (!dropdownEl.contains(event.target)) {
      event.stopPropagation();
      dropdownEl.classList.add('u-scale-y-0');

      document.removeEventListener('click', onOutsideClick, true);
      buttonEl.addEventListener('click', buttonHandler, true);
    }
  };
}

function bindMenuClick(buttonEl, dropdownEl) {
  if (!buttonEl || !dropdownEl) { return; }

  buttonEl.addEventListener('click', function onButtonClick(e) {
    e.stopPropagation();

    if (dropdownEl.classList.contains('u-scale-y-0')) {
      buttonEl.removeEventListener('click', onButtonClick, true);
      dropdownEl.classList.remove('u-scale-y-0');

      document.addEventListener('click', handleOutsideClick(buttonEl, dropdownEl, onButtonClick), true);
    }
  }, true);
}

function bindNavBarButtons() {
  const navBarEl = document.querySelector(NAV_BAR_SELECTOR);

  if (!navBarEl) { return; }

  const navBarMenuButtonEl = navBarEl.querySelector(NAV_BAR_MENU_BUTTON_SELECTOR);
  const navBarMenuEl = navBarEl.querySelector(NAV_BAR_MENU_SELECTOR);

  bindMenuClick(navBarMenuButtonEl, navBarMenuEl);
}

function bindCloseNotificationsButton() {
  const closeNotificationsButtonEl = document.querySelector(NOTIFICATIONS_CLOSE_BUTTON_SELECTOR);

  if (!closeNotificationsButtonEl) { return; }

  const notificationsPanel = document.querySelector(NAV_BAR_NOTIFICATIONS_SELECTOR);

  closeNotificationsButtonEl.addEventListener('click', () => {
    notificationsPanel.classList.add('u-scale-y-0');
  });
}

function bindTurboLoading() {
  document.addEventListener('turbo:before-fetch-request', ({ target }) => {
    applyTurboLoading(target);
  });
}

const documentReadyInitialise = async () => {
  autosize($('textarea'));

  bindTurboLoading();
  bindNavBarButtons();
  bindCloseNotificationsButton();
  toggleAllCaseFilesSelected();

  // NOTE(henryaj): disables turbo-drive on all forms and buttons, unless
  // explicitly enabled using a data-turbo: true attribute on the form itself.
  //
  // See https://stackoverflow.com/questions/70921317
  document.querySelectorAll('form').forEach((el) => {
    if (el.dataset.turbo === 'true') { return; }
    el.dataset.turbo = false;
  });

  $(() => {
    $('[data-toggle="tooltip"]').tooltip();
  });

  $(() => {
    $('table.js-table-clickable tr').on('click', (event) => {
      const link = event.target.parentElement.dataset.url;

      if (link) {
        window.location.href = link;
      }
    });
  });

  document.addEventListener('turbo:before-cache', () => {
    $('.selectpicker').selectpicker('destroy').addClass('selectpicker');
  });

  $('#js-registration-form').submit(() => {
    // store registered email from the form in localStorage so we can check it for bounces later.
    window.localStorage.setItem('registeredEmail', $('#user_email').val());
  });

  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    // In Firefox, including version 97.0, hidden fields are sometimes autocompleted.
    // So far I have only observed this on one form (the note form in the admin panel).

    const methodField = document.querySelector('#notes input[type=hidden][name="_method"]');
    const noteContentIsPresent = !(window._noteContentIsPresent === 'false' || window._noteContentIsPresent === false);

    if (methodField && methodField.value.length > 6 && noteContentIsPresent) {
      // The bug overwrites the _method field for updating notes, with a
      // base 64 encoded string. This puts it back to normal.
      methodField.value = 'patch';
    }

    if (noteContentIsPresent) {
      // The bug overwrites the trix editor field for notes when it is blank, with a
      // base 64 encoded string. This puts it back to normal.
      const trixNoteField = document.querySelector('#notes #content');
      if (trixNoteField) {
        trixNoteField.innerHTML = '<div></div>';
      }
    }
  }

  ahoy.trackClicks('#admin-panel-card #notes-tab');
  ahoy.trackClicks('#admin-panel-card #messages-tab');
  ahoy.trackClicks('#admin-panel-card #view-case-notes-btn');

  ahoy.trackClicks('[data-track=clicks]');

  $('#edit-current-note').click((event) => {
    event.preventDefault();

    $('.card-lender-admin-notes, #notes-tab').addClass('editing');
    $('#current-note').hide();
    $('#note-editor').show();
  });

  $('#cancel-edit-current-note').click((event) => {
    event.preventDefault();

    $('.card-lender-admin-notes, #notes-tab').removeClass('editing');
    $('#current-note').show();
    $('#note-editor').hide();
  });

  setTimeout(() => {
    if ($('#policy_master').is(':checked')) {
      $('button[data-id=policy_product_ids]').prop('disabled', true);
      $('select#policy_product_category_id').prop('disabled', true);
      $('.selectpicker').selectpicker('deselectAll');
    }
  }, 100);

  $('#policy_master').change(function disablePolicyApplyFields() {
    if (this.checked) {
      // disable other fields that pertain to which products the policy applies
      // to, as master policies apply globally
      $('button[data-id=policy_product_ids]').prop('disabled', true);
      $('select#policy_product_category_id').prop('disabled', true);
      $('select#policy_product_category_id').val('').change();

      $('.selectpicker').selectpicker('deselectAll');
    } else {
      $('button[data-id=policy_product_ids]').prop('disabled', false);
      $('select#policy_product_category_id').prop('disabled', false);
    }
  });

  // For all rich text areas, find any embedded attachments, and prevent downloading on them unless they are verified to be virus free.
  Array.from(document.getElementsByTagName('action-text-attachment')).forEach((embed) => {
    const downloadLink = embed.getElementsByTagName('a')[0];

    if (!downloadLink) { return; }

    const signedBlobId = downloadLink.href.split('/')[6];
    $.get(`/blobs/${signedBlobId}/virus_check.json`, (res) => {
      if (res.passed_security_check === false) {
        downloadLink.href = '';
        downloadLink.style['pointer-events'] = 'none';
        downloadLink.innerText = 'Download disabled: failed security check.';
        downloadLink.style.display = 'unset';
      } else if (res.scanned_for_viruses === false) {
        downloadLink.href = '';
        downloadLink.style['pointer-events'] = 'none';
        downloadLink.innerText = 'Download disabled pending virus scan.';
        downloadLink.style.display = 'unset';
      } else if (res.passed_security_check === true && res.scanned_for_viruses === true) {
        downloadLink.style.display = 'unset';
      }
    });
  });

  $('#send_2fa_email').on('ajax:success', () => {
    $('#send_email_success').show();
  }).on('ajax:error', () => {
    alert('Sorry, something went wrong while sending that email.');
  });

  await loadTrix();
};

$(document).on('turbo:load', async () => {
  window.doScroll = scrollFor;

  await documentReadyInitialise();
  $(() => {
    $('select.selectpicker:not(.no-selectpicker-init)').selectpicker();
  });
});

function enableBootstrapSelect() {
  $('select.selectpicker:not(.no-selectpicker-init)').selectpicker();
}
// This is used to make it availible in rails admin, It is far from ideal but after
// baning my head against a wall for a few hours trying to make rails admin work with this.
// I gave up and did this.
// I am hoping someone will be able to enlighten me on a better way to do this.
window.enableBootstrapSelect = enableBootstrapSelect;
