import { Controller } from '@hotwired/stimulus';
import { checkAccountNumberValidity, checkSortCodeValidity } from '../src/payment_details_validations';

export default class extends Controller {
  static targets = [
    'accountNumber',
    'sortCode',
  ];

  change(event) {
    const input = event.target;

    switch (input) {
      case this.accountNumberTarget:
        checkAccountNumberValidity(input);
        break;
      case this.sortCodeTarget:
        checkSortCodeValidity(input);
        break;
      default:
    }
  }
}
