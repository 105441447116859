import axios from 'axios';
import { Controller } from '@hotwired/stimulus';
import * as Sentry from '@sentry/browser';

export default class FcaCheckController extends Controller {
  static targets = ['fcaNumber', 'feedback', 'loading'];

  static computeClassListAndMessage({ exists, found, name }) {
    let message;
    const classList = found ? ['is-valid'] : ['is-invalid', '!u-border-red'];

    if (found) {
      message = `
        <div class="u-mt-2 u-text-green u-text-sm" data-fca-check-target="feedback">
          FCA number for <strong>${name}</strong> is valid.
        </div>
      `;
    } else {
      const text = exists
        ? 'There\'s already a Mast account registered with that FCA number. Try <a href="/users/sign_in">logging in</a> instead.'
        : 'FCA number was not found on the FCA register. Please check and try again.';

      message = `
        <div class="invalid-feedback u-mt-2 u-text-red u-text-sm" data-fca-check-target="feedback">
          ${text}
        </div>
      `;
    }

    return { classList, message };
  }

  connect() {
    Sentry.init({
      dsn: window._sentry_dsn,
      environment: 'production',
    });

    this.fcaCheckRequest = null;
  }

  removeExistingFeedback() {
    if (this.hasFeedbackTarget) { this.feedbackTarget.remove(); }
    if (this.hasLoadingTarget) { this.loadingTarget.remove(); }

    this.fcaNumberTarget.classList.remove('is-valid', 'is-invalid', '!u-border-red');
  }

  cancelExistingRequest() {
    if (!this.fcaCheckRequest) { return; }

    this.fcaCheckRequest.cancel();
    this.fcaCheckRequest = null;
  }

  addFcaNumberInvalidState() {
    this.fcaNumberTarget.classList.add('is-invalid', '!u-border-red');
    this.fcaNumberTarget.insertAdjacentHTML('afterend', `
      <div class="invalid-feedback u-mt-2 u-text-red u-text-sm" data-fca-check-target="feedback">
        FCA number must only contain letters and numbers.
      </div>
    `);
  }

  async runServerCheck() {
    this.fcaNumberTarget.insertAdjacentHTML('afterend', '<small class="shimmer u-mt-2 u-text-sm u-font-normal" data-fca-check-target="loading">Checking your FCA number...</small>');

    this.fcaCheckRequest = axios.CancelToken.source();

    try {
      const { data: { exists, found, name } } = await axios.get(`/users/exists?individual_fca_number=${this.fcaNumberTarget.value}`, { cancelToken: this.fcaCheckRequest.token });

      this.removeExistingFeedback();

      const { classList, message } = FcaCheckController.computeClassListAndMessage({ exists, found, name });

      this.fcaNumberTarget.classList.add(...classList);
      this.fcaNumberTarget.insertAdjacentHTML('afterend', message);
    } catch (error) {
      if (axios.isCancel(error)) { return; }

      Sentry.captureException(error);

      this.removeExistingFeedback();
      this.fcaCheck = null;

      this.fcaNumberTarget.classList.add('is-invalid', '!u-border-red');
      this.fcaNumberTarget.insertAdjacentHTML('afterend', `
        <div class="invalid-feedback u-mt-2 u-text-red u-text-sm" data-fca-check-target="feedback">
          Something went wrong checking your FCA number. Please refresh the page and try again.
        </div>
      `);
    } finally {
      this.fcaCheckRequest = null;
    }
  }

  async check() {
    this.removeExistingFeedback();

    this.cancelExistingRequest();

    if (this.fcaNumberTarget.value === '') { return; }

    if (!/^\w+$/.test(this.fcaNumberTarget.value)) {
      this.addFcaNumberInvalidState();

      return;
    }

    await this.runServerCheck();
  }
}
