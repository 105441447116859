const TURBO_LOADING_EL_SELECTOR = 'js-turbo-loading';
const TURBO_LOADING_CLASS = 'u-animate-pulse';

export default function applyTurboLoading(target) {
  if (target.tagName === 'HTML') { return; }

  let loadingEls = [];

  if (['A', 'BUTTON', 'INPUT'].includes(target.tagName)) {
    loadingEls.push(target);
  } else {
    loadingEls = target.querySelectorAll('a, button, input[type="submit"]');
  }

  loadingEls.forEach((tagEl) => {
    tagEl.classList.add(TURBO_LOADING_EL_SELECTOR, TURBO_LOADING_CLASS);
  });

  const removeLoading = () => {
    loadingEls.forEach((el) => {
      el.classList.remove(TURBO_LOADING_EL_SELECTOR, TURBO_LOADING_CLASS);
    });

    document.removeEventListener('turbo:submit-end', removeLoading);
    document.removeEventListener('turbo:frame-load', removeLoading);
  };

  document.addEventListener('turbo:submit-end', removeLoading);
  document.addEventListener('turbo:frame-load', removeLoading);
}
