import { Controller } from '@hotwired/stimulus';
import debounce from '../src/debounce';

export default class extends Controller {
  static targets = [
    'formtrigger',
    'triggerTypeSelect',
    'taskTemplateIdSelect',
    'transitionSelect',
  ];

  connect() {
    this.debouncedSave = debounce(this.saveTrigger.bind(this), 500);
    this.toggleOptionsVisibility();
  }

  async saveTrigger() {
    Turbo.navigator.submitForm(this.formtriggerTarget);
  }

  toggleOptionsVisibility() {
    const triggerTypeValue = this.triggerTypeSelectTarget.value;
    const taskTemplateSelectElement = this.taskTemplateIdSelectTarget.querySelector('select');
    const transitionSelectElement = this.transitionSelectTarget.querySelector('select');

    if (triggerTypeValue === 'AutomationTriggers::JourneyTransition') {
      this.transitionSelectTarget.style.display = 'contents';
      transitionSelectElement.removeAttribute('disabled');
    } else {
      this.taskTemplateIdSelectTarget.style.display = 'none';
      taskTemplateSelectElement.setAttribute('disabled', 'disabled');
      this.transitionSelectTarget.style.display = 'none';
      transitionSelectElement.setAttribute('disabled', 'disabled');
    }
  }
}
