import axios from 'axios';

import getCSRFToken from './getCSRFToken';

export default async function flash(type, message) {
  const { data: stream } = await axios.post(
    '/flashes',
    { authenticity_token: getCSRFToken(), type, message },
    { headers: { Accept: 'text/vnd.turbo-stream.html' } },
  );

  window.Turbo.renderStreamMessage(stream);
}
