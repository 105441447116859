import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    setTimeout(() => this.element.classList.remove('u-scale-y-0'), 100);

    if (this.element.dataset.persist === 'true') { return; }

    setTimeout(() => this.removeFlash(), 5000);
  }


  handleClick(event) {
    event.stopImmediatePropagation(); // prevent clicking 'through' the flash to the page behind
  }

  removeFlash(event) {
    this.element.classList.add('u-scale-y-0');
    setTimeout(() => this.element.remove(), 500);

    if (event) {
      event.stopImmediatePropagation(); // prevent clicking 'through' the flash to the page behind
    }
  }
}
