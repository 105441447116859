import { Controller } from '@hotwired/stimulus';
import Vue from 'vue';

import TagSelect from '../src/components/tagSelect.vue';

const VALID_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default class extends Controller {
  static targets = ['actionRoot', 'deleteAction', 'emailTagSelect', 'selectAction', 'userSelect'];

  static values = {
    formId: String,
    formValues: Object,
    usersSelectOptions: Object,
  };

  emailTagSelectTargetConnected(el) {
    const { dataset: { field, initialSelection, required } } = el;

    const app = new Vue({
      propsData: {
        field,
        formId: this.formIdValue,
        initialSelection: JSON.parse(initialSelection || '[]'),
        options: new Map(Object.entries(this.formValuesValue)),
        required: required === 'true',
        showFreetextInput: true,
        selectValueErrorMessage: 'Please ensure emails are valid.',
        requiredErrorMessage: 'Please add at least one recipient.',
        freetextInputRegex: VALID_EMAIL_REGEX,
      },
      ...TagSelect,
    });

    app.$mount(el);
  }

  userSelectTargetConnected(el) {
    const { dataset: { field, initialSelection, required } } = el;

    const app = new Vue({
      propsData: {
        field,
        formId: this.formIdValue,
        initialSelection: JSON.parse(initialSelection || '[]'),
        options: new Map(Object.entries(this.usersSelectOptionsValue)),
        required: required === 'true',
      },
      ...TagSelect,
    });

    app.$mount(el);
  }

  selectType(event) {
    const frameId = `action_type_${this.formIdValue}`;
    const frame = document.getElementById(frameId);

    let { url } = event.target.querySelector(`[value="${event.target.value}"]`).dataset;

    url ||= event.target.dataset.defaultUrl;

    frame.src = url;
    frame.reload();
  }

  deleteAction() {
    this.actionRootTarget.remove();
    this.deleteActionTarget.value = true;
  }
}
