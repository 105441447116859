import axios from 'axios';
import { Controller } from '@hotwired/stimulus';

export default class NotificationController extends Controller {
  static targets = ['text', 'unreadIcon'];

  static get authToken() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }

  async handleClick(event) {
    const { element: { dataset: { read, target, updateUrl } } } = this;

    if (read === 'true') { return; }

    event.preventDefault();

    const params = {
      authenticity_token: NotificationController.authToken,
      notification: { read_at: new Date() },
    };

    await axios.put(updateUrl, params);

    if (this.hasUnreadIconTarget) { this.unreadIconTarget.remove(); }

    if (target) {
      window.location.href = target;
    } else {
      this.textTarget.classList.remove('u-cursor-pointer');
    }
  }
}
