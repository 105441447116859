import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['notificationCount', 'notificationContainer'];

  get notificationsVisible() {
    return this._notificationsVisible;
  }

  set notificationsVisible(isVisible) {
    this.notificationContainerTarget.classList.toggle('u-scale-y-0', !isVisible);

    this._notificationsVisible = isVisible;
  }

  toggleNotifications() {
    this.notificationCountTarget.classList.add('u-hidden');

    this.notificationsVisible = !this.notificationsVisible;
  }
}
