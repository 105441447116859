import { Controller } from '@hotwired/stimulus';

export default class MessagesController extends Controller {
  static targets = ['formInput'];

  connect() {
    this.scrollToBottom();
  }

  submit() {
    this.formInputTarget.requestSubmit();
    this.formInputTarget.reset();
  }


  scrollToBottom() {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 250);
  }

  resetForm() {
    this.formInputTarget.reset();

    const noMessagesText = document.getElementById('js-no-messages-text');
    if (noMessagesText) { noMessagesText.remove(); }
  }
}
