<template>
<div
  v-on-clickaway="away"
  role="group"
  aria-label="Select Option"
  class="u-relative"
  @click="stopPropagationUnlessDisabled"
>
  <span
    v-if="disabled"
    class="
        u-block
        u-border-grey
        u-px-2
        u-py-1
        u-text-left
        u-truncate
      "
    :class="[
      {
        'u-w-44': !shrink,
      }
    ]"
  >
    <slot>
      {{ toggleText }}
    </slot>
  </span>

  <button
    v-else
    :id="'select-button-' + toggleText"
    class="u-block mast-select-button"
    type="button"
    role="button"
    aria-haspopup="listbox"
    :aria-expanded="active"
    @click="toggle"
  >
    <slot name="toggle">
      <dropdown-toggle-button
        :active="active"
        :class="toggleClasses"
      >
        {{ toggleText }}
      </dropdown-toggle-button>
    </slot>
  </button>

  <dropdown-content
    :labelled-by="'select-button-' + toggleText"
    :active="active"
    :class="contentClasses"
    :selected-values="selectedValues"
  >
    <slot name="content" />
  </dropdown-content>

  <select
    v-if="required"
    aria-hidden="true"
    class="
        u-right-2/4
        u-sr-only
      "
    multiple="true"
    :required="required"
  >
    <option
      v-for="value in truthySelectedValues"
      :key="value"
      :value="value"
      :selected="true"
    />
  </select>
</div>
</template>

<script>
import onClickaway from '../../vue/directives/clickaway';
import DropdownContent from './dropdownContent.vue';
import DropdownToggleButton from './dropdownToggleButton.vue';

export default {
  components: {
    DropdownContent,
    DropdownToggleButton,
  },
  directives: {
    onClickaway,
  },
  props: {
    active: {
      type: Boolean,

      required: true,
    },
    contentClasses: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,

      default: false,
    },
    required: {
      type: Boolean,

      default: false,
    },
    selectedValues: {
      type: Array,

      default: () => ([]),
    },
    toggleClasses: {
      type: Array,
      default: () => [],
    },
    toggleText: {
      type: String,
      default: 'Toggle',
    },
    shrink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    truthySelectedValues() {
      return this.selectedValues.filter(Boolean);
    },
  },
  methods: {
    away() {
      this.$emit('toggle', false);
    },
    stopPropagationUnlessDisabled(event) {
      if (!this.disabled) { event.stopPropagation(); }
    },
    toggle() {
      this.$emit('toggle', !this.active);
    },
  },
};
</script>
