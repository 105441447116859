import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static outlets = ['navigation'];

  handleClick(event) {
    if (
      this.hasNavigationOutlet
      && this.navigationOutlet.notificationsVisible
      && !this.navigationOutlet.notificationContainerTarget.contains(event.target)
    ) {
      this.navigationOutlet.notificationsVisible = false;
    }
  }
}
