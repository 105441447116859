import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'error',
    'submit',
    'text',
  ];

  #valid = false;

  get valid() { return this.#valid; }

  set valid(newValue) { this.#valid = newValue; }

  submitTargetConnected() {
    this.toggleValidState();
  }

  handleFormValueChange() {
    this.valid = this.textTarget.editor.getDocument().toString().trim() !== '';

    this.toggleValidState();
  }

  toggleValidState() {
    this.submitTarget.classList.toggle('button-primary', this.valid);
    this.submitTarget.classList.toggle('button-disabled', !this.valid);
    this.submitTarget.toggleAttribute('disabled', !this.valid);

    this.errorTarget.classList.toggle('u-hidden', this.valid);
  }
}
