import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'mandateLevelCheckbox',
    'destroy',
    'form',
    'maximumLoanAmount',
    'maximumLtv',
    'journeyIds',
  ];

  connect() {
    this.setFormVisibility();
  }

  setFormVisibility() {
    if (this.mandateLevelCheckboxTarget.checked) {
      this.enableForm();
    } else {
      this.disableForm();
    }
  }

  enableForm() {
    this.formTarget.style.display = 'contents';
    this.journeyIdsTarget.required = true;
    this.maximumLoanAmountTarget.required = true;
    this.maximumLtvTarget.required = true;
    this.destroyTarget.value = 'false';
  }

  disableForm() {
    this.journeyIdsTarget.required = false;
    this.maximumLoanAmountTarget.required = false;
    this.maximumLtvTarget.required = false;
    this.formTarget.style.display = 'none';
    this.destroyTarget.value = 'true';
  }
}
