import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  showModal() {
    document.getElementById('deleteConditionTemplate').showModal();
  }

  closeModal() {
    document.getElementById('deleteConditionTemplate').close();
  }
}
