import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['masterFields', 'nonMasterFields'];

  handleMasterChange({ target: { checked } }) {
    if (checked) {
      this.masterFieldsTarget.removeAttribute('disabled');

      this.nonMasterFieldsTarget.classList.add('u-hidden');
      this.nonMasterFieldsTarget.setAttribute('disabled', true);
    } else {
      this.masterFieldsTarget.setAttribute('disabled', true);

      this.nonMasterFieldsTarget.classList.remove('u-hidden');
      this.nonMasterFieldsTarget.removeAttribute('disabled');
    }
  }
}
