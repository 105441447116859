import axios from 'axios';
import { Controller } from '@hotwired/stimulus';
import * as Sentry from '@sentry/browser';

import getCSRFToken from '../src/getCSRFToken';

export default class FcaCheckController extends Controller {
  static targets = [
    'error',
    'input',
    'loading',
  ];

  static values = {
    url: String,
  };

  async handleChange() {
    this.errorTarget.textContent = '';

    const { value: number } = this.inputTarget;

    if (!number || !number.length) { return; }

    if (!/^\w+$/.test(number)) {
      this.errorTarget.textContent = 'Firm number should be alphanumeric.';

      return;
    }

    this.loadingTarget.classList.remove('u-hidden');
    try {
      await axios.post(
        this.urlValue,
        { authenticity_token: getCSRFToken(), number },
      );
    } catch (error) {
      this.loadingTarget.classList.add('u-hidden');
      this.errorTarget.textContent = 'Something went wrong. Please try again later.';
      Sentry.captureException(error);
    }
  }
}
