import { Controller } from '@hotwired/stimulus';
import Vue from 'vue';
import ActionsDropdown from '../src/components/actionsDropdown.vue';

export default class extends Controller {
  static targets = ['dialog', 'actionsDropdown', 'feeActionsDropdown'];

  actionsDropdownTargetConnected(el) {
    const { paymentId } = el.dataset;

    const app = new Vue({
      components: {
        ActionsDropdown,
      },
      data() {
        return { paymentId };
      },
      methods: {
        handleDelete() {
          const deletePaymentModal = document.getElementById(`deletePaymentModal-${this.paymentId}`);
          deletePaymentModal.showModal();
        },
      },
    });

    app.$mount(el);
  }

  feeActionsDropdownTargetConnected(el) {
    const { paymentFeeId } = el.dataset;

    const app = new Vue({
      components: {
        ActionsDropdown,
      },
      data() {
        return { paymentFeeId };
      },
      methods: {
        handleDelete() {
          const deletePaymentFeeModal = document.getElementById(`deletePaymentFeeModal-${this.paymentFeeId}`);
          deletePaymentFeeModal.showModal();
        },
      },
    });

    app.$mount(el);
  }

  showModal(event) {
    event.preventDefault();
    this.dialogTarget.showModal();
  }

  disableLink({ target }) {
    target.classList.add('button-disabled');
    target.setAttribute('aria-disabled', 'true');
  }

  closeModal() {
    this.dialogTarget.close();
  }

  showFeeModal(e) {
    const { paymentId } = e.target.dataset;
    const dialog = document.querySelector(`dialog[data-payment-id="${paymentId}"]`);
    this.cleanFormInputs(dialog);
    const submitButton = dialog.querySelector('input[type="submit"]');
    if (!submitButton.classList.contains('button-disabled')) {
      submitButton.disabled = true;
      submitButton.classList.add('button-disabled', true);
    }
    dialog.showModal();
  }

  closeFeeModal(e) {
    const dialog = e.target.closest('dialog');
    dialog.close();
  }

  cleanFormInputs(dialog) {
    dialog.querySelector('#payment_fee_amount').value = '';
    dialog.querySelector('#payment_fee_name').value = '';
  }

  closeDeletePaymentModal({ target: { dataset: { paymentId } } }) {
    const deletePaymentModal = document.getElementById(`deletePaymentModal-${paymentId}`);

    deletePaymentModal.close();
  }

  closeDeletePaymentFeeModal(e) {
    const deletePaymentFeeModal = document.getElementById(`deletePaymentFeeModal-${e.target.dataset.paymentFeeId}`);
    deletePaymentFeeModal.close();
  }

  copy(e) {
    e.preventDefault();
    const { paymentLink } = e.currentTarget.dataset;
    const textarea = document.createElement('textarea');
    textarea.textContent = paymentLink;
    document.body.append(textarea);
    textarea.select();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(paymentLink);
    } else {
      document.execCommand('copy');
    }
    const text = e.currentTarget.querySelector('.js-payments-link-text');
    if (!text) return;

    text.textContent = 'Copied!';
    setTimeout(() => {
      text.textContent = 'Copy';
    }, 3000);
    textarea.remove();
  }

  validate(e) {
    const nameInput = e.target.closest('form').querySelector('input[name="payment_fee[name]"]');
    const amountInput = e.target.closest('form').querySelector('input[name="payment_fee[amount]"]');
    const isNameEmpty = !nameInput.value;
    const isAmountEmpty = !amountInput.value;
    const submitButton = e.target.closest('form').querySelector('input[type="submit"]');
    submitButton.disabled = isNameEmpty || isAmountEmpty;
    submitButton.classList.toggle('button-disabled', isNameEmpty || isAmountEmpty);
  }
}
