var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    u-min-h-0\n    u-grow\n    tag-select\n  ",on:{"click":_vm.handleInsideInteraction}},[_c('div',{class:[
    'u-border',
    'u-w-full',
    'u-relative',
    'after:u-absolute',
    'after:u-font-[FontAwesome]',
    'after:u-pointer-events-none',
    'after:u-right-2',
    'after:u-top-1/2',
    'after:-u-translate-y-1/2',
    _vm.showingOptions ? 'u-rounded-t-md after:u-content-[\'\\f077\']' : 'u-rounded-md after:u-content-[\'\\f078\']',
    {
      'u-border-b-0': _vm.showingOptions,
    }
  ]},[(_vm.selectedValues.size || _vm.showFreetextInput === false)?_c('ul',{staticClass:"\n        u-flex\n        u-flex-wrap\n        u-gap-1\n        u-p-2\n        u-m-0\n        u-min-h-[40px]\n        u-max-[300px]\n        u-text-sm\n        u-w-full\n      "},_vm._l((_vm.selectedValues.entries()),function(ref){
  var value = ref[0];
  var title = ref[1];
return _c('li',{key:value,class:[
          'u-border',
          'u-px-2',
          'u-py-1',
          'u-rounded-full',
          'u-w-max',
          _vm.selectedValuesWithErrors.has(title) ? 'u-border-red' : 'u-border-grey' ],attrs:{"data-title":value,"type":"button","value":value},on:{"click":_vm.handleRemoveClick}},[_vm._v("\n        "+_vm._s(title)+"\n      ")])}),0):_vm._e(),_vm._v(" "),(_vm.showFreetextInput)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentInput),expression:"currentInput"}],ref:"input",class:[
        'u-px-2',
        'u-min-h-[40px]',
        'u-outline-none',
        'u-rounded-md',
        'u-w-full' ],attrs:{"placeholder":"Type email or select user","required":_vm.validationRequired,"type":"text"},domProps:{"value":(_vm.currentInput)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.handleInputSubmit.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.currentInput=$event.target.value}}}):_vm._e()]),_vm._v(" "),(_vm.showingOptions)?_c('div',{staticClass:"\n      u-border\n      u-rounded-b-md\n    "},_vm._l((_vm.options.entries()),function(ref){
      var value = ref[0];
      var title = ref[1];
return _c('button',{key:value,class:[
        'u-items-center',
        'u-flex',
        'u-justify-between',
        'u-px-6',
        'u-py-2',
        'u-w-full' ],attrs:{"data-value":value,"data-title":title,"type":"button"},on:{"click":_vm.toggleEmailClick}},[_vm._v("\n      "+_vm._s(title)+"\n\n      "),(_vm.selectedValues.has(value))?_c('i',{staticClass:"fa fa-check"}):_vm._e()])}),0):_vm._e(),_vm._v(" "),(_vm.selectedValues.size)?_vm._l((_vm.selectedValues.keys()),function(value){return _c('input',{key:value,attrs:{"name":_vm.fieldName,"type":"hidden"},domProps:{"value":value}})}):_c('input',{attrs:{"name":_vm.nonArrayFieldName,"type":"hidden","value":""}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }