<template>
  <h5
    class="
      u-text-base
      u-font-bold
      u-mt-2.5"
  >
    <slot>
      Subheader
    </slot>
  </h5>
</template>

<script>
export default {};
</script>
