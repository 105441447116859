export default function debounce(callback, wait, { maxWait } = {}) {
  let timer;
  let maxTimer;

  return function setupDebounce(...args) {
    if (timer) window.clearTimeout(timer);

    timer = window.setTimeout(() => {
      window.clearTimeout(maxTimer);
      maxTimer = null;

      callback.apply(this, args);
    }, wait);

    if (maxWait && !maxTimer) {
      maxTimer = window.setTimeout(() => {
        clearTimeout(timer);
        maxTimer = null;

        callback.apply(this, args);
      }, maxWait);
    }
  };
}
