import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = [
    'chevron',
    'details',
    'templateId',
    'templateDescription',
  ];

  async displayTaskTemplate() {
    if (this.templateIdTarget.value !== '') {
      try {
        const resp = await axios.get(`/task_templates/${this.templateIdTarget.value}/description.json`);
        this.templateDescriptionTarget.innerHTML = resp.data;
      } catch {
        this.templateDescriptionTarget.innerHTML = 'Something went wrong loading this task template.';
      }
    }

    this.templateDescriptionTarget.classList.toggle('u-hidden', !this.templateIdTarget.value);
  }

  toggleDescription() {
    this.chevronTarget.classList.toggle('u-rotate-180');
    this.detailsTarget.classList.toggle('u-hidden');
  }
}
