import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  #currentStatus = null;

  static targets = [
    'approvedLoanAmountAtDipInput',
    'dipAmountContainer',
    'fundsReleasedAmountContainer',
    'fundsReleasedInput',
    'offerBlobIdInput',
    'offerMadeUploadFields',
    'offerMadeUploadFileInput',
    'offerMadeUploadFileLabel',
    'select',
  ];

  static values = {
    customOffer: Boolean,
  };

  get currentStatus() {
    return this.#currentStatus;
  }

  set currentStatus(value) {
    this.#currentStatus = value;

    this.showRelevantFields();
  }

  selectTargetConnected(newTarget) {
    this.currentStatus = newTarget.value;
  }

  handleStatusChange() {
    this.currentStatus = this.selectTarget.value;
  }

  handleFileUpload() {
    const file = this.offerMadeUploadFileInputTarget.files[0];
    if (file) {
      this.offerMadeUploadFileLabelTarget.textContent = file.name;
    }
  }

  showRelevantFields() {
    this.fundsReleasedAmountContainerTarget.classList.toggle('u-hidden', this.currentStatus !== 'funds_released');
    this.fundsReleasedInputTarget.toggleAttribute('disabled', this.currentStatus !== 'funds_released');

    if (!this.customOfferValue) {
      this.offerMadeUploadFieldsTarget.classList.toggle('u-hidden', this.currentStatus !== 'offer_made');
      if (this.hasOfferMadeUploadFileInputTarget) {
        this.offerMadeUploadFileInputTarget.toggleAttribute('disabled', this.currentStatus !== 'offer_made');
      }
    }

    this.dipAmountContainerTarget.classList.toggle('u-hidden', this.currentStatus !== 'dip_approved');
    this.approvedLoanAmountAtDipInputTarget.toggleAttribute('disabled', this.currentStatus !== 'dip_approved');
  }
}
