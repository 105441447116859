import consumer from './consumer';

consumer.subscriptions.create('Noticed::NotificationChannel', {
  received(data) {
    if (data.lender_id !== window._lender_id) { return; }

    this.updateCounter();
    this.addToast(data);
    this.renderMessage(data);
  },

  updateCounter() {
    const notificationCountEl = document.querySelector('.js-unread-notification-count');

    if (notificationCountEl.classList.contains('u-hidden')) {
      notificationCountEl.textContent = 1;
      notificationCountEl.classList.remove('u-hidden');
    } else {
      notificationCountEl.textContent = parseInt(notificationCountEl.textContent, 10) + 1;
    }
  },

  addToast(data) {
    const toaster = document.getElementById('toaster');
    const toast = document.createElement('div');
    toast.innerHTML = data.toastHtml;
    toast.style.display = 'none';
    toaster.appendChild(toast);
    setTimeout(() => { toast.style.display = 'block'; }, 300);

    const closeToastBtn = toast.querySelector('.js-close-toast-btn');
    closeToastBtn.addEventListener('click', () => { toast.remove(); });
    setTimeout(() => { toast.style.display = 'none'; }, 5000);
  },

  renderMessage(data) {
    if (data.turbo) {
      window.Turbo.renderStreamMessage(data.html);
    } else {
      const notificationsPanel = document.querySelector('.js-notifications-panel');
      notificationsPanel.insertAdjacentHTML('afterbegin', data.html);
    }
  },
});
