import { Controller } from '@hotwired/stimulus';
import applyTurboLoading from '../src/turbo_loading';

export default class extends Controller {
  static targets = ['button'];

  handleClick() {
    applyTurboLoading(this.buttonTarget);
  }
}
