import { Controller } from '@hotwired/stimulus';

// Allows a section within a form to add/remove rows.
// Connects to data-controller="repeatable-fields"
export default class extends Controller {
  static targets = ['row', 'templateRow'];

  addRow(event) {
    event.preventDefault();

    const newRow = this.templateRowTarget.cloneNode(true);
    newRow.classList.remove('d-none');
    newRow.querySelectorAll('input').forEach((input) => { input.value = ''; });
    newRow.querySelectorAll('label').forEach((label) => label.remove());

    this.recursiveRenumber(newRow);

    const id = this.getOriginFieldId(event);
    if (id === 'mainAddButton') {
      this.rowTargets[this.rowTargets.length - 1].after(newRow);
    } else {
      // N.B.: by inserting the new row mid-way through the rows, we lose the guarantee that all
      // the rows are displayed in order of their index. This is to allow users to insert a new row,
      // following a different sort, e.g. allowing them to insert an LTV cap by loan size of £5 in between £1 and £10.
      this.getRowTargetById(id).before(newRow);
    }
    newRow.querySelectorAll('.form-group input')[0].focus();

    // Prepare for teardown
    newRow.setAttribute('data-added-by-stimulus', true);
  }

  // Does not actually delete the row, in order to keep us from having to renumber all rows.
  // Instead, it sets the _destroy hidden input value to true, and hides the row.
  deleteRow(event) {
    event.preventDefault();

    const id = this.getOriginFieldId(event);
    const row = this.getRowTargetById(id);
    const destroyInput = row.querySelector('input.hidden-destroy');

    destroyInput.value = true; // Required for accepts_nested_attributes_for to delete/not create this row
    row.style.display = 'none';

    // Prepare for teardown
    row.setAttribute('data-hidden-by-stimulus', true);
  }

  // Recursively replace ids in certain attributes on an element and all its children.
  recursiveRenumber(el) {
    Array.from(el.attributes).filter((a) => (['for', 'id', 'name'].includes(a.name) || a.name.substring(0, 4) === 'data')).forEach((attribute) => {
      el.setAttribute(attribute.name, attribute.value.replace(/\d+|mainAddButton/, `${this.rowTargets.length}`));
    });

    if (el.childElementCount > 0) {
      Array.from(el.children).forEach((child) => this.recursiveRenumber(child));
    }
  }

  getOriginFieldId(event) {
    // TODO: Can't get stimulus action params to work (e.g. event.params.idx) so getting param manually.
    return event.target.closest('.btn-container').dataset.repeatableFieldsIdxParam;
  }

  getRowTargetById(id) {
    return this.rowTargets.filter((row) => row.id === `row-${id}`)[0];
  }

  teardown() {
    this.rowTargets.forEach((row) => {
      if (row.dataset.hiddenByStimulus) { row.style.display = 'flex'; }
      if (row.dataset.addedByStimulus) { row.remove(); }
    });
  }
}
