import axios from 'axios';
import { Controller } from '@hotwired/stimulus';
import getCSRFToken from '../src/getCSRFToken';

const FORMULATE_FORM_SELECTOR = '#formulate-form';
const FORMULATE_FORM_ADD_MORE_SELECTOR = '.formulate-input-group-add-more';

const NAVBAR_APP_TAB_SELECTOR = '.js-application-tab';

export default class extends Controller {
  connect() {
    this.applicationTabEl = document.querySelector(NAVBAR_APP_TAB_SELECTOR);
  }

  disconnect() {
    this.observer.disconnect();
  }

  print() { window.print(); }

  unlockForm() {
    window._form_disabled = false;
    const formEl = document.querySelector(FORMULATE_FORM_SELECTOR);
    formEl.querySelectorAll('input, textarea, button, select').forEach((el) => {
      el.removeAttribute('disabled');
    });

    document.querySelectorAll(FORMULATE_FORM_ADD_MORE_SELECTOR).forEach((addMoreEl) => {
      addMoreEl.style.removeProperty('display');
    });

    // Close Actions tab by re-opening Application tab
    this.applicationTabEl.click();
    this.postAuditEvent(window._application_id);
  }

  async postAuditEvent(applicationId) {
    axios.post(
      `${applicationId}/audit_events`,
      {
        audit_event: 'application.unlock_form',
        description: `Application ${applicationId} has been unlocked`,
      },
      {
        headers: {
          'X-CSRF-Token': getCSRFToken(),
          Accept: 'application/json',
        },
      },
    );
  }
}
