<template>
  <transition name="dropdown-content">
    <div
      v-if="active"
      role="listbox"
      :aria-labelledby="labelledBy"
      class="
        u-absolute
        u-bg-white
        u-border
        u-border-grey
        u-h-max
        u-drop-shadow-md
        u-left-0
        u-min-w-max
        u-max-h-60
        u-mt-2
        u-origin-top-left
        u-overflow-y-auto
        u-px-2
        u-py-2
        u-rounded-lg
        u-z-10
      "
    >
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,

      required: true,
    },
    labelledBy: {
      type: String,
      default: 'selectButton',
    },
  },
};
</script>

<style>
.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: all 0.2s;
}
.dropdown-content-enter,
.dropdown-content-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
</style>
