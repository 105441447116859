import { uploadChecks } from './upload_checks';

const LOADING_CLASSES = [
  'loading-animation',
  'mx-auto',
  'my-3',
];
const TRIX_SELECTOR = 'trix-editor';
const SHOW_UPLOAD_SELECTOR = `${TRIX_SELECTOR}.show-upload`;

const createLoadingEl = () => {
  const divEl = document.createElement('div');

  divEl.classList.add(...LOADING_CLASSES);

  return divEl;
};

const importFor = async (trixEls) => {
  const loadingTemplateEl = createLoadingEl();

  const loadingEls = trixEls.reduce(
    (accumulator, trixEl) => {
      const loadingEl = loadingTemplateEl.cloneNode();
      trixEl.append(loadingEl);
      accumulator.push(loadingEl);

      return accumulator;
    },
    [],
  );

  await import(/* webpackChunkName: 'trix' */ 'trix');

  loadingEls.forEach((loadingEl) => loadingEl.remove());
};

export const setupUploadFields = () => {
  document.querySelectorAll(SHOW_UPLOAD_SELECTOR).forEach((uploadEl) => {
    const toolbarId = uploadEl.getAttribute('toolbar');
    const toolBarEl = document.querySelector(`#${toolbarId} .trix-button-group--file-tools`);

    if (toolBarEl) { toolBarEl.style.display = 'block'; }
  });

  document.addEventListener('trix-file-accept', (event) => { uploadChecks(event); });
};

export default async () => {
  const trixEls = Array.from(document.querySelectorAll(TRIX_SELECTOR));

  if (trixEls.length) {
    await importFor(trixEls);
    requestIdleCallback(() => {
      setupUploadFields();
    });
  }
};
