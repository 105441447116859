import { Controller } from '@hotwired/stimulus';
import debounce from '../src/debounce';

export default class extends Controller {
  static targets = [
    'form',
    'activateCheckbox',
  ];

  connect() {
    this.debouncedSave = debounce(this.submit.bind(this), 500);
  }

  async submit() {
    Turbo.navigator.submitForm(this.formTarget);
  }
}
