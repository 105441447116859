import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  #validPhoneNumberFn = null;

  static targets = [
    'phoneNumberField',
    'phoneNumberFeedback',
    'phoneNumberLoading',
  ];

  async phoneNumberFieldTargetConnected() {
    const { isValidPhoneNumber } = await import('libphonenumber-js');

    this.#validPhoneNumberFn = isValidPhoneNumber;
    this.phoneNumberFieldTarget.toggleAttribute('disabled', false);

    if (this.hasPhoneNumberLoadingTarget) {
      this.phoneNumberLoadingTarget.classList.add('u-hidden');
    }
  }

  handlePhoneNumberChange({ target: { value } }) {
    let isValid = false;

    try {
      if (!this.hasPhoneNumberFieldTarget) { return; }

      if (!value?.length) {
        this.toggleInvalidPhoneNumberClasses({ hasErrors: false });
        this.phoneNumberFieldTarget.setCustomValidity('');

        return;
      }

      if (this.#validPhoneNumberFn) {
        isValid = this.#validPhoneNumberFn(value, 'GB', { extract: false });
      }

      this.toggleInvalidPhoneNumberClasses({ hasErrors: !isValid });

      this.phoneNumberFieldTarget.setCustomValidity(isValid ? '' : 'Phone number must be valid');
    } finally {
      this.phoneNumberFieldTarget.reportValidity();
    }
  }

  toggleInvalidPhoneNumberClasses({ hasErrors }) {
    this.phoneNumberFieldTarget.classList.toggle('is-invalid', hasErrors);
    this.phoneNumberFieldTarget.classList.toggle('!u-border-red', hasErrors);
    this.phoneNumberFeedbackTarget.classList.toggle('u-hidden', !hasErrors);
  }
};
