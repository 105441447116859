<template>
  <button
    :value="value"
    role="option"
    type="button"
    class="
      mast-option-button
      u-flex
      u-justify-between
      u-gap-2
      u-items-center
      u-w-full
      u-text-left
      hover:u-bg-gray-200
      u-my-1
      u-py-1
      u-border-grey
      u-border-b
    "
    @click="handleClick"
  >
    <div
      role="button"
      class="u-flex u-gap-2 u-items-center"
    >
      <slot>
        {{ value }}
      </slot>
    </div>
    <icon
      v-if="isSelected"
      name="tick"
      :size="10"
    />
  </button>
</template>

<script>
import Icon from '../icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    selectedValues: {
      type: Array,

      default: () => ([]),
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSelected() {
      return this.selectedValuesSet.has(this.value);
    },
    selectedValuesSet() {
      return new Set(this.selectedValues);
    },
  },
  methods: {
    handleClick({ currentTarget: { value } }) {
      this.$emit('item-clicked', value, { currentlySelected: this.isSelected });
    },
  },
};
</script>
