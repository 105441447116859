<template>
<component
  :is="wrapperTag"
  v-if="hasWrapper"
  aria-hidden="true"
  :class="wrapperClassWithDefaults"
>
  <svg
    aria-hidden="true"
    :class="classList"
    :height="size"
    :width="size"
  >
    <use :xlink:href="`#${name}`" />
  </svg>
</component>

<svg
  v-else
  aria-hidden="true"
  :class="classList"
  :height="size"
  :width="size"
>
  <use :xlink:href="`#${name}`" />
</svg>
</template>

<script>
const CIRCLE_ICON_CLASSES = [
  'u-rounded-full',
  'u-text-white',
  'u-p-1.5',
];

export default {
  props: {
    classList: {
      type: Array,

      default: () => [],
    },
    bgColor: {
      type: String,
      default: null,
    },
    name: {
      type: String,
    },
    size: {
      type: Number,
      default: 16,
    },
    iconStyle: {
      type: String,
      default: null,
    },
    wrap: {
      type: Boolean,

      default: false,
    },
    wrapperClass: {
      type: Array,

      default: () => [],
    },
    wrapperTag: {
      type: String,

      default: 'div',
    },
    ringColorClass: {
      type: String,
      default: 'u-ring-grey',
    },
  },
  computed: {
    wrapperClassWithDefaults() {
      return [
        'u-flex',
        'u-items-center',
        'u-justify-center',
        'u-w-fit',
        {
          'u-bg-blue': this.bgColor === null && this.iconStyle === 'circle',
          [this.bgColor]: this.bgColor !== null,
        },
        ...this.getClasses,
        ...this.wrapperClass,
      ];
    },
    getClasses() {
      if (this.iconStyle === 'circle') {
        return CIRCLE_ICON_CLASSES;
      } if (this.iconStyle === 'ring') {
        return this.ringIconClasses;
      }
      return [];
    },
    ringIconClasses() {
      return [
        this.ringColorClass,
        'u-ring-inset',
        'u-ring-1',
        'u-rounded-full',
        'u-p-1.5',
      ];
    },
    hasWrapper() {
      return this.wrap || this.iconStyle === 'circle' || this.iconStyle === 'ring';
    },
  },
};
</script>
