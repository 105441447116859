// This file is a refactored version of the vue-clickaway package, adjusted to use the capture phase
// of the event listener to avoid stopPropagation() preventing the event firing.

// Removed import of Vue and version checks as they are specific to Vue 2

// @SECTION: implementation

const HANDLER = '_vue_clickaway_handler';

function bind(el, binding, vnode) {
  unbind(el);

  const vm = vnode.context;

  const callback = binding.value;
  if (typeof callback !== 'function') {
    console.warn(`v-${binding.name}="${binding.expression}" expects a function value, got ${callback}`);
    return;
  }

  let initialMacrotaskEnded = false;
  setTimeout(() => {
    initialMacrotaskEnded = true;
  }, 0);

  el[HANDLER] = function (ev) {
    const path = ev.path || (ev.composedPath ? ev.composedPath() : undefined);
    if (initialMacrotaskEnded && (path ? path.indexOf(el) < 0 : !el.contains(ev.target))) {
      return callback.call(vm, ev);
    }
  };

  document.documentElement.addEventListener('click', el[HANDLER], true);
}

function unbind(el) {
  document.documentElement.removeEventListener('click', el[HANDLER], true);
  delete el[HANDLER];
}

export default {
  bind,
  update(el, binding) {
    if (binding.value === binding.oldValue) return;
    bind(el, binding);
  },
  unbind,
};
