<template>
  <div
    class="
      u-min-h-0
      u-grow
      tag-select
    "
    @click="handleInsideInteraction"
  >
    <!-- These classes need to be defined here for Tailwind dynamic class generation to work for icons below -->
    <!-- after:u-content-['\f077'] -->
    <!-- after:u-content-['\f078'] -->

    <div :class="[
      'u-border',
      'u-w-full',
      'u-relative',
      'after:u-absolute',
      'after:u-font-[FontAwesome]',
      'after:u-pointer-events-none',
      'after:u-right-2',
      'after:u-top-1/2',
      'after:-u-translate-y-1/2',
      showingOptions ? 'u-rounded-t-md after:u-content-[\'\\f077\']' : 'u-rounded-md after:u-content-[\'\\f078\']',
      {
        'u-border-b-0': showingOptions,
      }
    ]">
      <ul
        v-if="selectedValues.size || showFreetextInput === false"
        class="
          u-flex
          u-flex-wrap
          u-gap-1
          u-p-2
          u-m-0
          u-min-h-[40px]
          u-max-[300px]
          u-text-sm
          u-w-full
        "
      >
        <li
          v-for="[value, title] in selectedValues.entries()"
          :class="[
            'u-border',
            'u-px-2',
            'u-py-1',
            'u-rounded-full',
            'u-w-max',
            selectedValuesWithErrors.has(title) ? 'u-border-red' : 'u-border-grey',
          ]"
          :data-title="value"
          :key="value"
          type="button"
          :value="value"
          @click="handleRemoveClick"
        >
          {{ title }}
        </li>
      </ul>

      <input
        v-if="showFreetextInput"
        v-model="currentInput"
        :class="[
          'u-px-2',
          'u-min-h-[40px]',
          'u-outline-none',
          'u-rounded-md',
          'u-w-full',
        ]"
        placeholder="Type email or select user"
        ref="input"
        :required="validationRequired"
        type="text"
        @keydown.enter.prevent="handleInputSubmit"
      >
    </div>

    <div
      v-if="showingOptions"
      class="
        u-border
        u-rounded-b-md
      "
    >
      <button
        v-for="[value, title] in options.entries()"
        @click="toggleEmailClick"
        :class="[
          'u-items-center',
          'u-flex',
          'u-justify-between',
          'u-px-6',
          'u-py-2',
          'u-w-full',
        ]"
        :key="value"
        :data-value="value"
        :data-title="title"
        type="button"
      >
        {{ title }}

        <i
          v-if="selectedValues.has(value)"
          class="fa fa-check"
        ></i>
      </button>
    </div>

    <template v-if="selectedValues.size">
      <input
        v-for="value in selectedValues.keys()"
        :key="value"
        :name="fieldName"
        type="hidden"
        :value="value"
      >
    </template>
    <input
      v-else
      :name="nonArrayFieldName"
      type="hidden"
      value=""
    >
  </div>
</template>

<script>

export default {
  props: {
    field: {
      type: String,
    },
    formId: {
      type: String,
    },
    initialSelection: {
      type: Array,

      default: () => ([]),
    },
    options: {
      type: Map,
    },
    required: {
      type: Boolean,

      default: false,
    },
    showFreetextInput: {
      type: Boolean,

      default: false,
    },
    freetextInputRegex: {
      type: RegExp,
      default: null,
    },
    selectValueErrorMessage: {
      type: String,
      default: 'Please ensure all items are valid.',
    },
    requiredErrorMessage: {
      type: String,
      default: 'Please add at least one option.',
    }
  },
  data() {
    return {
      currentInput: '',
      selectedValues: new Map(),
      showingOptions: false,
    }
  },
  computed: {
    fieldName() {
      return `automation[actions_attributes][${this.formId}][${this.field}][]`;
    },
    nonArrayFieldName() {
      return `automation[actions_attributes][${this.formId}][${this.field}]`;
    },
    optionValuesSet() {
      return new Set(this.options.values());
    },
    selectedValuesWithErrors() {
      return [...this.selectedValues.values()].reduce((map, value) => {
        if (!this.optionValuesSet.has(value) && (this.freetextInputRegex && !this.freetextInputRegex.test(value))) {
          map.set(value, value);
        }

        return map;
      }, new Map);
    },
    validationRequired() {
      return (this.required && !this.selectedValues.size) || !!this.selectedValuesWithErrors.size;
    },
  },
  mounted() {
    const self = this;
    const selectedMap = this.initialSelection.reduce((map, value) => {
      const option = [...self.options].find(([val, _]) => val === value);
      const selected = option || [value, value];

      map.set(...selected);

      return map;
    }, new Map());

    this.selectedValues = selectedMap;
  },
  watch: {
    selectedValues() {
      let error;

      if (this.selectedValuesWithErrors.size) {
        error = this.selectValueErrorMessage;
      } else if (this.validationRequired) {
        error = this.requiredErrorMessage;
      } else {
        error = '';
      }

      if (this.$refs.input) {
        this.$refs.input.setCustomValidity(error);
      }
    },
  },
  methods: {
    handleInsideInteraction() {
      this.showingOptions = true;

      document.addEventListener('click', this.handleOutsideInteraction);
      document.addEventListener('focusin', this.handleOutsideInteraction);
    },
    handleInputSubmit(e) {
      this.addSelection(this.currentInput, this.currentInput);

      this.currentInput = '';
    },
    handleOutsideInteraction({ target }) {
      if (this.$el.contains(target)) { return; }

      this.showingOptions = false;

      document.removeEventListener('click', this.handleOutsideInteraction);
      document.removeEventListener('focusin', this.handleOutsideInteraction);
    },
    handleRemoveClick({ currentTarget: { dataset: { title } } }) {
      this.removeSelection(title);
    },
    toggleEmailClick({ currentTarget: { dataset: { title, value } } }) {
      if (this.selectedValues.has(value)) {
        this.removeSelection(value);
      } else {
        this.addSelection(title, value);
      }
    },
    addSelection(title, value) {
      this.selectedValues = new Map([
        ...this.selectedValues,
        [value, title],
      ]);
    },
    removeSelection(value) {
      const selectedValuesClone = structuredClone(this.selectedValues);
      selectedValuesClone.delete(value);

      this.selectedValues = selectedValuesClone;
    }
  }
}
</script>
