import { Controller } from '@hotwired/stimulus';
import Vue from 'vue';
import axios from 'axios';
import ActionsDropdown from '../src/components/actionsDropdown.vue';
import getCSRFToken from '../src/getCSRFToken';

export default class NoteSectionsController extends Controller {
  static targets = [
    'noteSection',
    'actionsDropdown',
  ];

  initialize() {
    this.activeNoteSectionId = this.noteSectionTargets[0].dataset.noteSectionId;
    this.updateActive();
  }

  activateNoteSection(event) {
    this.activeNoteSectionId = event.target.dataset.noteSectionId;
    this.updateActive();
  }

  closeModal(event) {
    const deleteNoteModal = document.getElementById(`deleteNoteModal-${event.target.dataset.noteId}`);
    deleteNoteModal.close();
  }

  actionsDropdownTargetConnected(el) {
    const self = this;
    const app = new Vue({
      components: {
        ActionsDropdown,
      },

      methods: {
        handleAction(action) {
          if (action === 'delete') {
            this.handleDelete();
          } else if (action === 'edit') {
            this.handleEdit();
          }
        },

        async handleDelete() {
          const { noteId } = el.dataset;
          const deleteNoteModal = document.getElementById(`deleteNoteModal-${noteId}`);
          deleteNoteModal.showModal();
        },

        async handleEdit() {
          const { noteId } = el.dataset;
          const response = await axios.get(
            `/note_sections/${self.activeNoteSectionId}/notes/${noteId}/edit`,
            {
              headers: {
                'X-CSRF-Token': getCSRFToken(),
                Accept: 'text/vnd.turbo-stream.html',
              },
            },
          );

          window.Turbo.renderStreamMessage(response.data);
        },
      },
    });

    app.$mount(el);
  }

  updateActive() {
    this.noteSectionTargets.forEach((noteSection) => {
      noteSection.classList.remove(
        'u-transition',
        'u-shadow-sm',
        'u-bg-green-10',
        'hover:u-bg-green-5',
        'hover:u-bg-green-15',
        'hover:u-shadow-sm',
      );
      const { noteSectionId } = noteSection.dataset;
      if (this.activeNoteSectionId === noteSectionId) {
        noteSection.classList.add('u-transition', 'u-bg-green-10', 'u-shadow-sm', 'hover:u-bg-green-5', 'active:u-shadow-inner');
      } else {
        noteSection.classList.add('u-transition', 'hover:u-bg-green-15', 'hover:u-shadow-sm', 'active:u-shadow-inner');
      }
    });
  }
}
