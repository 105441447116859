import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'confirmationModal',
  ];

  connect() {
    this.confirmed = false;
  }

  showConfirmation(event) {
    if (this.confirmed) {
      this.confirmationModalTarget.close();
    } else {
      event.preventDefault();
      this.confirmationModalTarget.showModal();
    }
  }

  confirmSubmit() {
    this.confirmed = true;

    this.element.requestSubmit();
  }

  cancelSubmit() {
    this.confirmationModalTarget.close();
  }
}
