import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'clubsCheckBox',
    'selectBox',
    'cashback',
    'cashbackPercent',
    'productFee',
    'productFeePercent',
    'rate',
    'rateSelect',
    'differenceRate',
    'differenceRateDiv',
    'initialRate',
    'interestType',
  ];

  connect() {
    this.toggleSelectBox();
    this.cashbackToggle();
    this.productFeeToggle();
    this.setInitialRate();
    this.toggleDifferenceRate();
  }

  setInitialRate() {
    const smallElement = this.initialRateTarget.parentNode.nextSibling;
    if (this.interestTypeTarget.value === 'variable') {
      this.initialRateTarget.setAttribute('disabled', true);
      const differenceRateValue = this.differenceRateTarget.value.length ? parseFloat(this.differenceRateTarget.value) : 0.0;
      const rateValue = parseFloat(this.rateTargets[this.rateSelectTarget.selectedIndex].dataset.value);
      const value = (rateValue + differenceRateValue).toFixed(2);

      this.initialRateTarget.value = value;
      smallElement.innerHTML = 'This is automatically calculated from the selected SVR plus the differences rate given.';
    } else {
      this.initialRateTarget.removeAttribute('disabled', true);
      smallElement.innerHTML = 'The introductory interest rate, if any.';
    }
  }

  toggleInterestType() {
    this.toggleDifferenceRate();
    this.setInitialRate();
  }

  toggleDifferenceRate() {
    if (this.interestTypeTarget.value === 'variable') {
      this.differenceRateDivTarget.classList.remove('u-hidden');
    } else {
      this.differenceRateDivTarget.classList.add('u-hidden');
      this.differenceRateTarget.value = '';
    }
  }

  toggleSelectBox() {
    if (this.hasSelectBoxTarget) {
      this.selectBoxTarget.classList.toggle('u-hidden', !this.clubsCheckBoxTarget.checked);
    }
  }

  cashbackToggle() {
    if (this.hasValue(this.cashbackTarget)) {
      this.disable(this.cashbackPercentTarget);
    } else { this.enable(this.cashbackPercentTarget); }

    if (this.hasValue(this.cashbackPercentTarget)) {
      this.disable(this.cashbackTarget);
    } else {
      this.enable(this.cashbackTarget);
    }
  }

  productFeeToggle() {
    if (this.hasValue(this.productFeeTarget)) {
      this.disable(this.productFeePercentTarget);
    } else { this.enable(this.productFeePercentTarget); }

    if (this.hasValue(this.productFeePercentTarget)) {
      this.disable(this.productFeeTarget);
    } else {
      this.enable(this.productFeeTarget);
    }
  }

  hasValue(target) {
    return target.value.trim().length !== 0;
  }

  enable(target) {
    target.removeAttribute('readonly');
    target.classList.remove('button-disabled');
  }

  disable(target) {
    target.setAttribute('readonly', true);
    target.classList.add('button-disabled');
  }
}
