import { Controller } from '@hotwired/stimulus';
import { setupUploadFields } from '../src/trix_loader';

export default class NotesController extends Controller {
  static targets = [
    'cancel',
    'edit',
    'editor',
    'form',
    'formHeading',
    'formHeadingWrapper',
    'formWrapper',
    'hiddenInput',
    'note',
    'noNotes',
    'section',
  ];

  get ownedNoteCount() {
    return this.noteTargets.filter((note) => note.dataset.isOwnNote === 'true').length;
  }

  get editor() {
    return this.editorTarget.editor;
  }

  connect() {
    this.initialFormUrl = this.formTarget.getAttribute('action');
  }


  editorTargetConnected() {
    setupUploadFields();
  }

  noteTargetConnected() {
    if (this.ownedNoteCount > 0) {
      this.formWrapperTarget.classList.add('u-hidden');
    }

    this.noNotesTarget.classList.add('u-hidden');
  }

  noteTargetDisconnected() {
    if (this.ownedNoteCount === 0) {
      this.formWrapperTarget.classList.remove('u-hidden');
    }

    if (this.noteTargets.length === 0) {
      this.noNotesTarget.classList.remove('u-hidden');
    }

    this.resetForm();
  }

  handleStopEditingClick(event) {
    if (this.ownedNoteCount > 0) {
      this.formWrapperTarget.classList.add('u-hidden');
    }

    this.resetForm();

    window.doScroll(event, this.sectionTarget, { behavior: 'instant' });
  }

  handleEditNoteClick(event) {
    const {
      dataset: { noteContent, noteUrl },
    } = event.target;

    this.convertFormToTargetEdit(noteUrl);

    this.formWrapperTarget.classList.remove('u-hidden');

    this.populateEditForm(noteContent);

    this.activateAndScrollToEditForm(event);
  }

  convertFormToTargetEdit(noteUrl) {
    this.formTarget.setAttribute('action', noteUrl);
    this.hiddenInputTarget.removeAttribute('disabled');
  }

  populateEditForm(noteContent) {
    this.editor.loadHTML(noteContent);
    const { length } = this.editor.getDocument().toString();
    this.editor.setSelectedRange(length - 1);
  }

  activateAndScrollToEditForm(event) {
    this.formHeadingTarget.textContent = 'Edit note';
    this.cancelTarget.classList.remove('u-hidden');
    window.doScroll(event, this.formHeadingTarget);
  }

  resetForm() {
    this.formTarget.setAttribute('action', this.initialFormUrl);
    this.editor.loadHTML('');
    this.hiddenInputTarget.setAttribute('disabled', true);
    this.formHeadingTarget.textContent = 'New note';
    this.cancelTarget.classList.add('u-hidden');
  }

  handleSubmitEnd() {
    this.resetForm();
  }
}
