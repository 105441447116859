import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import axios from 'axios';
import debounce from '../src/debounce';
import getCSRFToken from '../src/getCSRFToken';
import loader from '../src/trix_loader';

export default class extends Controller {
  static targets = [
    'form',
    'selectPicker',
    'bodyEditor',
  ];

  connect() {
    this.debouncedSave = debounce(Turbo.navigator.submitForm.bind(Turbo.navigator), 500);
  }

  bodyEditorTargetConnected() {
    loader();
  }

  async submit() {
    this.debouncedSave(this.formTarget);
  }

  selectPickerTargetConnected() {
    this.selectPickerTargets.forEach((selectPickerTarget) => {
      $(selectPickerTarget).selectpicker();
      $(selectPickerTarget).on('hide.bs.select', (_) => {
        this.submit();
      });
    });
  }

  async deleteAction() {
    const { automationId, actionId } = this.formTarget.dataset;
    await axios.delete(
      `/automations/${automationId}/actions/${actionId}`,
      { headers: { 'X-CSRF-Token': getCSRFToken(), Accept: 'text/vnd.turbo-stream.html' } },
    ).then((response) => {
      window.Turbo.renderStreamMessage(response.data);
    });
  }
}

