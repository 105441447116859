var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'u-block',
    'u-border',
    'u-rounded-[4px]',
    'u-border-grey',
    'u-py-1',
    'u-px-2',
    'u-w-44',
    'u-text-left',
    'u-truncate',
    { 'u-bg-grey-30': _vm.active } ],attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('btn-clicked')}}},[_vm._t("default",function(){return [_vm._v("\n    "+_vm._s(_vm.text)+"\n  ")]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }