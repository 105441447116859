import axios from 'axios';
import { Controller } from '@hotwired/stimulus';
import { checkAccountNameValidity, checkAccountNumberValidity, checkSortCodeValidity } from '../src/payment_details_validations';

export default class extends Controller {
  // TODO(henryaj, 22-02-23): Could do with a refactor; much of this was
  // converted from the old-style jQuery nightmare but this is a good start.
  static targets = [
    'appointedRepresentative',
    'appointedRepresentativeFields',
    'brokerSupportCopy',
    'brokerTypeRadio',
    'correspondenceAddressCheckbox',
    'correspondenceAddressNullifier',
    'correspondenceAddressWrapper',
    'email',
    'irnField',
    'irnFields',
    'password',
    'passwordConfirmation',
    'paymentDetailFields',
  ];

  get brokerType() {
    return this.brokerTypeRadioTargets.find((radioTarget) => radioTarget.checked)?.value;
  }

  get brokerIsAppointedRepresentative() {
    return !this.appointedRepresentativeTarget.checked && this.brokerType === 'broker';
  }

  async change(event) {
    const input = event.target;

    switch (input) {
      case this.emailTarget:
        await this.checkDuplicateEmail(input);
        break;
      case this.passwordTarget:
        await this.checkPasswordValidity(input);
        break;
      case this.passwordConfirmationTarget:
        this.checkPasswordConfirmation(input);
        break;
      default:
    }
  }

  async checkDuplicateEmail(input) {
    try {
      const email = input.value;
      const response = await axios.get(`/users/exists?email=${email}`);
      const { data } = response;
      input.nextElementSibling?.remove();

      if (data.exists === true) {
        input.classList.add('is-invalid', '!u-border-red');
        input.insertAdjacentHTML('afterend', `
          <div class="invalid-feedback u-mt-2 u-text-red u-text-sm">
            There's already a Mast account with that email address. Try <a class="u-text-primary u-underline" href="/users/sign_in">logging in</a> instead.
          </div>
        `);
      } else {
        input.classList.remove('is-invalid', '!u-border-red');
      }
    } catch (error) {
      console.error(error);
    }
  }

  async checkPasswordValidity(input) {
  // NOTE: should this run on keyup instead?
    try {
      const password = input.value;
      const response = await axios.post('/users/valid', { password });
      const { data } = response;

      for (let i = 0; i < 2; i += 1) {
        input.nextElementSibling?.remove();
      }

      if (data.valid === false) {
        input.classList.add('is-invalid', '!u-border-red');
        data.errors.forEach((error) => {
          input.insertAdjacentHTML('afterend', `<div class="invalid-feedback u-mt-2 u-text-red u-text-sm">${error}</div>`);
        });
      } else {
        input.classList.remove('is-invalid', '!u-border-red');
      }
    } catch (error) {
      console.error(error);
    }
  }

  checkPasswordConfirmation(input) {
    const password = this.element.querySelector('input#user_password').value;
    const passwordConfirmation = input.value;
    input.nextElementSibling?.remove();
    if (password !== passwordConfirmation) {
      input.classList.add('is-invalid', '!u-border-red');
      input.insertAdjacentHTML('afterend', '<div class="invalid-feedback u-mt-2 u-text-red u-text-sm">Passwords don\'t match</div>');
    } else {
      input.classList.remove('!u-border-red');
    }
  }

  showAppointedRepFields() {
    const isAppointedRepresentative = this.appointedRepresentativeTarget.checked;

    this.appointedRepresentativeFieldsTarget.classList.toggle('u-hidden', !isAppointedRepresentative);
    this.appointedRepresentativeFieldsTarget.toggleAttribute('disabled', !isAppointedRepresentative);
  }

  togglePaymentDetails() {
    if (!this.hasPaymentDetailFieldsTarget) { return; }

    this.paymentDetailFieldsTarget.classList.toggle('u-hidden', !this.brokerIsAppointedRepresentative);
    this.paymentDetailFieldsTarget.toggleAttribute('disabled', !this.brokerIsAppointedRepresentative);
  }

  toggleIrnFields() {
    if (!this.hasIrnFieldsTarget) { return; }

    this.irnFieldsTarget.classList.toggle('u-hidden', this.brokerType !== 'broker');

    if (!this.hasIrnFieldTarget) { return; }

    this.irnFieldTarget.toggleAttribute('disabled', this.brokerType !== 'broker');
  }

  toggleBrokerSupportCopy() {
    this.brokerSupportCopyTarget.classList.toggle('u-hidden', this.brokerType !== 'broker support');
  }

  toggleCorrespondenceAddress() {
    const { correspondenceAddressCheckboxTarget: { checked } } = this;

    this.correspondenceAddressWrapperTarget.classList.toggle('u-hidden', !checked);
    this.correspondenceAddressWrapperTarget.toggleAttribute('disabled', !checked);

    this.correspondenceAddressNullifierTarget.toggleAttribute('disabled', checked);
  }

  validateAccountName(input) {
    checkAccountNameValidity(input.target);
  }

  validateAccountNumber(input) {
    checkAccountNumberValidity(input.target);
  }

  validateSortCode(input) {
    checkSortCodeValidity(input.target);
  }
}
