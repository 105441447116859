import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  copy(e) {
    e.preventDefault();
    const { documentLink } = e.currentTarget.dataset;
    const textarea = document.createElement('textarea');
    textarea.textContent = documentLink;
    document.body.append(textarea);
    textarea.select();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(documentLink);
    } else {
      document.execCommand('copy');
    }
    const text = e.currentTarget.querySelector('.js-documents-link-text');
    if (!text) return;

    text.textContent = 'Copied!';
    setTimeout(() => {
      text.textContent = 'Copy';
    }, 3000);
    textarea.remove();
  }
}
