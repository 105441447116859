import getNavHeight from './nav_height';

export default function scrollFor(event, scrollTargetIdOrElement, { scrollTargetMargin = 16, behavior = 'smooth' } = {}) {
  const scrollTargetEl = (typeof (scrollTargetIdOrElement) === 'string')
    ? document.getElementById(scrollTargetIdOrElement)
    : scrollTargetIdOrElement;

  if (!scrollTargetEl) { return; }
  event.preventDefault();

  const { top: elScrollTop } = scrollTargetEl.getBoundingClientRect();
  const top = elScrollTop + window.scrollY - getNavHeight() - scrollTargetMargin;

  window.scrollTo({ top, behavior });
}
