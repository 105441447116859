import axios from 'axios';
import { Controller } from '@hotwired/stimulus';
import throttle from 'lodash/throttle';

export default class extends Controller {
  static targets = ['form', 'output', 'offerValue', 'confirmOfferValue', 'offerValueWarning', 'submit'];

  connect() {
    const throttledPost = throttle(async () => {
      // Check if offer_value and confirm_offer_value match
      const offerValue = this.offerValueTarget.value;
      const confirmOfferValue = this.confirmOfferValueTarget.value;

      if (offerValue && confirmOfferValue) {
        if (offerValue === confirmOfferValue) {
          this.offerValueWarningTarget.classList.add('u-hidden');
          this.submitTarget.disabled = false;
        } else {
          this.offerValueWarningTarget.classList.remove('u-hidden');
          this.submitTarget.disabled = true;
        }
      }

      await this.doPost();
    }, 100);

    const formInputs = this.formTarget.querySelectorAll('input, select, textarea');

    const updateHandler = () => {
      throttledPost();
    };

    formInputs.forEach((input) => {
      input.addEventListener('input', updateHandler);
    });

    setTimeout(async () => {
      await this.doPost();
    }, 1000);
  }

  async doPost() {
    try {
      const formData = new FormData(this.formTarget);

      const { data: { output } } = await axios.post(window._preview_endpoint, formData);

      const outputText = output || '';
      this.outputTarget.innerHTML = outputText;
    } catch (error) {
      console.error(error);
    }
  }
}
